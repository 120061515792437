/**
 * Константы приложения
 */

const DOMAIN_DEVELOPMENT = "dev-dashboard.kitfort.ru",
  DOMAIN_PRODUCTION = "dashboard.kitfort.ru",
  SPA_ROOT = "/spa",
  SEARCH_MODE_REPORT = "report",
  SEARCH_MODE_PRODUCT = "product",
  SEARCH_MODE_PRODUCT_CANDIDATE = "product_candidate",
  SEARCH_MODE_CONTACT = "contact",
  SEARCH_MODE_PROD_ORDER = "prod_order",
  SEARCH_MODE_SHIP_ORDER = "ship_order",
  PAGE_TITLE = "Dashboard",
  PRIMARY_COLOR = "#6B17B2";
/*
 * При удаленной работе и отладке
 *
 * localStorage.setItem('dev_token', 'персональный токен'); В Auth.php обязательно можно взять или добавить этот токен
 *
 * Этот параметр нужен только для работы из дома
 * localStorage.setItem('dev_api_port', '9000') , где 9000 проброшенный порт до dev-сервера (443 порт https в офисе)
 */
const detectEnvironment = () => {
    if (
      typeof process.env != "undefined" &&
      process.env.NODE_ENV == "development"
    ) {
      return "development";
    } else {
      if (process.env.NODE_ENV == "production") {
        return location.href.includes(DOMAIN_DEVELOPMENT)
          ? "development"
          : "production";
      }
    }
  },
  // JS запущен после сборки через webpack?
  isBuild = () => {
    return !(
      typeof process.env != "undefined" && process.env.NODE_ENV == "development"
    );
  },
  // Dev-окружение (не учитывает сборка или работа с исходниками)
  isDevEnv = (): boolean => {
    return detectEnvironment() == "development";
  },
  /**
   * Возвращает ссылку на логотип dashboard
   * @returns string
   */
  dasboardIcon = () => require("@/assets/img/icons/icon_dashboard.png");
export {
  isDevEnv,
  isBuild,
  dasboardIcon,
  SPA_ROOT,
  DOMAIN_DEVELOPMENT,
  DOMAIN_PRODUCTION,
  SEARCH_MODE_REPORT,
  SEARCH_MODE_PRODUCT,
  SEARCH_MODE_PRODUCT_CANDIDATE,
  SEARCH_MODE_CONTACT,
  SEARCH_MODE_PROD_ORDER,
  SEARCH_MODE_SHIP_ORDER,
  PAGE_TITLE,
  PRIMARY_COLOR,
};
