
import { defineComponent } from "vue";
import type { PropType } from "vue";
import MenuItem from "@/models/index/index/MenuItem";
import BaseLink from "@/components/base/BaseLink.vue";
import {
  defineTarget,
  getBaseUrl,
  getDocumentRootUrl,
} from "@/composables/url";

export default defineComponent({
  components: {
    BaseLink,
  },
  props: {
    menuItem: {
      type: Object as PropType<MenuItem>,
      required: true,
    },
  },
  computed: {
    imageLink() {
      // return typeof this.menuItem !== "undefined" && this.menuItem.image
      //   ? this.menuItem.image
      //   : getDocumentRootUrl() + "/img/menu_items/default.jpeg";
      // return typeof this.menuItem !== "undefined" && this.menuItem.image
      //   ? getDocumentRootUrl() + "/img/menu_items/" + this.menuItem.image
      //   : "https://dashboard.kitfort.ru/template/img/favicon.png";
      if (typeof this.menuItem !== "undefined" && this.menuItem.image) {
        const pattern = /^(https:)/;
        if (pattern.test(this.menuItem.image)) {
          return this.menuItem.image;
        } else {
          return (
            getDocumentRootUrl() + "/img/menu_items/" + this.menuItem.image
          );
        }
      } else {
        return getBaseUrl() + "/template/img/favicon.png";
      }
    },
  },
  methods: {
    defineTarget,
  },
});
