
import { defineComponent } from "vue";
import { useUserStore } from "@/stores/User";
import { isDevEnv } from "@/composables/constants";
import UserState from "@/models/stores/UserState";
import { getBaseUrl } from "@/composables/url";
export default defineComponent({
  data() {
    return {
      debugDesign: false && isDevEnv(),
      color: "cyan",
    };
  },
  computed: {
    avatarClass(): string {
      let result = "avatar";
      if (this.debugDesign) {
        result += " avatar--debug";
      }
      return result;
    },
    userStore(): UserState {
      return useUserStore();
    },
    username(): string {
      return this.userStore.name || "Гость";
    },
    avatar(): string {
      return (
        this.userStore.avatar ||
        getBaseUrl() + "/template/img/avatars/kitfort-user-no-image.png"
      );
    },
  },
});
