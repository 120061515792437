/**
 * Методы для работы со ссылками
 */

//import router from "@/router";
import { useRouter } from "vue-router";
import {
  DOMAIN_DEVELOPMENT,
  DOMAIN_PRODUCTION,
  isDevEnv,
  SPA_ROOT,
} from "./constants";

const /**
   * Возвращает истину, если переданная ссылка ведет
   * на старый дизайн Dashboard
   * @param {string}
   * @return {boolean}
   */
  isOldDashboardUrl = (url: string): boolean => {
    return (
      url.indexOf(isDevEnv() ? DOMAIN_DEVELOPMENT : DOMAIN_PRODUCTION) != -1
    );
  },
  /**
   * Возвращает истину, если url относительный
   * @param {string}
   * @returns {boolean}
   */
  isRelativeUrl = (url: string) => /^\//.test(url),
  /**
   * Возвращает истину, если url абсолютный
   * @param {string}
   * @returns {boolean}
   */
  isAbsoluteUrl = (url: string) => !isRelativeUrl(url),
  /**
   * Определяет необходимость в свойстве target
   * @param {string} url
   * @returns {string}
   */
  defineTarget = (url: string): string => {
    if (isOldDashboardUrl(url) || isRelativeUrl(url)) {
      // ссылки с dashboard и spa открываем в этой же вкладке
      return "_self";
    }
    return "_blank";
  },
  getDevPort = (): string => {
    return localStorage.getItem("dev_api_port") && isDevEnv()
      ? ":" + localStorage.getItem("dev_api_port")
      : "";
  },
  // Получить адрес бекенда
  getBaseUrl = (): string =>
    "https://" +
    (isDevEnv() ? DOMAIN_DEVELOPMENT : DOMAIN_PRODUCTION) +
    getDevPort(),
  // Возвращает URL на старый dashboard
  getLegacyUrl = (relativeUrl: string): string => getBaseUrl() + relativeUrl,
  /**
   * Возвращает путь до файлов из папки public (до сборки)
   * до сборки достаточно указывать /spa,
   * но после они из папки public попадут в dist
   * @returns {string}
   */
  getDocumentRootUrl = (): string => SPA_ROOT,
  // При помощи роутера делает относительную ссылку на новый дизайн
  makeUrl = (
    routeName: string,
    params?: Record<string, string | number>,
    query?: Record<string, string | number>
  ): string => {
    const router = useRouter(),
      routeParams = router.resolve({
        name: routeName,
        params: params,
        query: query,
      });
    return routeParams.href;
  },
  // Возвращает ссылку на товар
  getProductViewUrl = (productId: number): string => {
    return makeUrl("productView", {
      id: productId,
    });
  },
  /**
   * Возвращает ссылку на скачивание файла
   * @apram fileId id из таблицы files
   */
  getDownloadFileUrl = (fileId: number): string => {
    return getLegacyUrl("/download_file/" + fileId);
  };

export {
  isOldDashboardUrl,
  isRelativeUrl,
  isAbsoluteUrl,
  defineTarget,
  getBaseUrl,
  getLegacyUrl,
  getDocumentRootUrl,
  makeUrl,
  getProductViewUrl,
  getDownloadFileUrl,
};
