
import { StyleValue, defineComponent } from "vue";
import { useRootStore } from "@/stores/Root";
import AppSearchModelWrapper from "@/components/app/AppSearchModelWrapper.vue";
import AppAutocompleteResults from "@/components/app/AppAutocompleteResults.vue";
import { SearchModelNameValues } from "@/models/index/index/SearchModelNameValues";
import { kitDebounce } from "@/composables/delays";
import AutocompleteResponse from "@/models/base/AutocompleteResponse";
import { loadSuggestAutocomplete } from "@/services/SiteService";
import {
  SEARCH_MODE_PRODUCT,
  SEARCH_MODE_REPORT,
} from "@/composables/constants";
import { isOldDashboardUrl, defineTarget } from "@/composables/url";
import MenuItem from "@/models/index/index/MenuItem";
/** @var {number} MIN_SEARCH_FILTER_LENGTH
 * Минимальная длина поисковой строки, для которого будет применен поиск
 */
const MIN_SEARCH_FILTER_LENGTH = 3,
  /**
   * @var {number} MIN_AUTOCOMPLETE_SEARCH_FILTER_LENGTH
   * Минимальная длина поисковой строки для работы автодополнения
   */
  MIN_AUTOCOMPLETE_SEARCH_FILTER_LENGTH = 2;

export default defineComponent({
  components: { AppSearchModelWrapper, AppAutocompleteResults },
  data() {
    return {
      imageSize: 32,
      searchFilter: "" as string,
      filterFn: null as null | ReturnType<typeof kitDebounce>,
      autocompleteFn: null as null | ReturnType<typeof kitDebounce>,
      cursorIndex: 0 as number,
      autocompleteData: [] as AutocompleteResponse[],
      resizeObserver: null as null | ResizeObserver,
      autocompleteResultDropdownWidth: 0 as number,
    };
  },
  mounted() {
    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries.length > 0) {
        const searchInputWrapper = this.$refs
          .search_input_wrapper as HTMLElement;
        this.autocompleteResultDropdownWidth = searchInputWrapper.offsetWidth;
      }
    });
    this.resizeObserver.observe(
      document.querySelector(".search-input-wrapper") as Element
    );
  },
  unmounted() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  created() {
    this.filterFn = kitDebounce(this.applySearch, 500);
    this.autocompleteFn = kitDebounce(this.loadSuggestAutocomplete, 400);
    this.searchFilter = this.searchFilterValue;
  },
  computed: {
    /** истина, когда поиск открыт во весь экран */
    isSearchOpen(): boolean {
      return useRootStore().isSearchOpen;
    },
    /**
     * Возвращает значение для поиска
     * @returns string
     */
    searchFilterValue(): string {
      return useRootStore().searchFilterValue;
    },
    /**
     * Показать список с автодополнением?
     * @returns boolean
     */
    showAutocomplete() {
      return useRootStore().showAutocomplete;
    },
    btnClass(): string {
      return this.isSearchOpen ? "opened" : "closed";
    },
    imageViewBox(): string {
      return "0 0 " + this.imageSize + " " + this.imageSize;
    },
    searchModelName() {
      return useRootStore().searchModelName;
    },
    /**
     * Возвращает css переменную с base z-index
     * @returns object
     */
    autocompleteCssVar(): StyleValue {
      return {
        "--base-z-index": useRootStore().getZIndex(),
      };
    },
  },
  methods: {
    /**
     * Открывает/закрывает шторку
     */
    clickBtn(): void {
      //this.isSearchOpen = !this.isSearchOpen;
      useRootStore().toggleSearchOpen();
    },
    /**
     * Обработчик клика по одной из кнопок
     */
    focus() {
      if (this.isSearchOpen) {
        return;
      }
      this.$refs.search_input.dispatchEvent(new Event("search-input-focus"));
    },
    /**
     * Применяет поиск по введенным символам
     */
    applySearch() {
      useRootStore().setSearchFilterValue(this.searchFilter);
      useRootStore().setSearchPage(1);
      useRootStore().loadSearchData();
    },
    // Запрашивает возможные варианты для автодополнения
    loadSuggestAutocomplete() {
      useRootStore().setShowAutocompleteValue(true);
      loadSuggestAutocomplete<AutocompleteResponse[]>(
        this.searchModelName,
        this.searchFilter
      ).then((response) => {
        this.autocompleteData = response;
      });
    },
    /**
     * Очищает поле ввода
     */
    clearSearchFilterBtn() {
      this.searchFilter = "";
      this.applySearch();
    },
    /**
     * Обработчик смены режима отображения (отчеты, товары...)
     * @param {SearchModelNameValues} value
     */
    setSearchModelName(value: SearchModelNameValues) {
      useRootStore().setSearchModelName(value);
      this.searchFilter = "";
      useRootStore().setSearchFilterValue("");
      // useRootStore().setSearchPage(1);
      // if (value == SEARCH_MODE_REPORT) {
      // Если режим сменили на Отчеты, то сразу подгрузим новый список
      useRootStore().loadSearchData();
      // }
      // this.$refs.search_input.focus();
    },
    /**
     * Обработчик нажатия на клавиши в поле поиска
     * @param {KeyboardEvent} event
     */
    onKeyup(event: KeyboardEvent) {
      const canApplySearch =
        !this.searchFilter ||
        this.searchFilter.length >= MIN_SEARCH_FILTER_LENGTH;
      if (event.key.match(/Enter/)) {
        if (this.cursorIndex) {
          // Выбрали элемент из автодополнения
          if (
            this.autocompleteFn != null &&
            this.searchFilter.length &&
            this.showAutocomplete
          ) {
            // Выбираем значение под курсором, если есть список с автодополнением
            this.$refs.autocomplete_list.selectItemUnderCursor();
          }
        } else {
          if (this.filterFn != null) {
            // Поиск
            if (canApplySearch) {
              // Если был сброс поиска или если в поиске более 2 символов
              this.filterFn();
            } else {
              this.$notify(
                `Необходимо указать хотя бы ${MIN_SEARCH_FILTER_LENGTH} символа`,
                "error"
              );
            }
          }
        }
        // Скрываем автодополнение
        useRootStore().setShowAutocompleteValue(false);
      } else if (
        event.key.match(/Escape/) &&
        this.searchFilter.length >= MIN_AUTOCOMPLETE_SEARCH_FILTER_LENGTH
      ) {
        // закрыть список с автодополнением
        useRootStore().setShowAutocompleteValue(false);
      } else if (event.key.match(/ArrowUp|ArrowDown/i)) {
        // Сдвиг курсора
        const diff = event.key.match(/ArrowUp/i) ? -1 : 1;
        let nextIndex =
          (this.autocompleteData.length + 1 + this.cursorIndex + diff) %
          (this.autocompleteData.length + 1);
        if (!nextIndex) {
          // Не даем выйти за длину списка с автодополнением
          nextIndex = 1;
        }
        this.setCursorIndexAt(nextIndex);
      } else if (
        event.key.match(/[-a-zа-яё0-9\s]/giu) &&
        !["ArrowLeft", "ArrowRight"].includes(event.key)
      ) {
        if (this.filterFn != null && canApplySearch) {
          // Поиск
          this.filterFn();
        }
        if (
          this.autocompleteFn != null &&
          this.searchFilter.length >= MIN_AUTOCOMPLETE_SEARCH_FILTER_LENGTH
        ) {
          // Автодополнение
          useRootStore().setShowAutocompleteValue(true);
          this.autocompleteFn();
        }
        this.setCursorIndexAt(0);
      }
    },
    // Закрывает список с автодополнением
    closeAutocomplete() {
      useRootStore().setShowAutocompleteValue(false);
    },
    /**
     * Ставит курсор на позицию
     * @param {number} index
     */
    setCursorIndexAt(index: number) {
      this.cursorIndex = index;
    },
    /**
     * Подставляет выбранное значение из автодополнения в строку с поиском
     * @param {AutocompleteResponse} autocompleteItem
     */
    setAutocompleteSearchFilterValue(autocompleteItem: AutocompleteResponse) {
      useRootStore().setShowAutocompleteValue(false);
      this.cursorIndex = 0;
      if (this.searchModelName == SEARCH_MODE_REPORT) {
        // При поиске по отчетам и нажатии на него переходим в отчет
        this.searchFilter = autocompleteItem.label.replace(/<[^>]+>/g, "");
        useRootStore().setSearchFilterValue(this.searchFilter);
        useRootStore().setSearchPage(1);
        useRootStore()
          .loadSearchData()
          .then(() => {
            const selectedMenuItem = useRootStore().searchData.items.filter(
              (item) => {
                const data = item as MenuItem;
                return "hit-" + data.menu_item_id == autocompleteItem.value;
              }
            )[0] as MenuItem;

            if (typeof selectedMenuItem != "undefined") {
              // Если был выбран именно пункт меню
              const link = selectedMenuItem.link,
                target = this.defineTarget(link);
              if (target == "_blank" || isOldDashboardUrl(link)) {
                // Страницы для открытия в новой вкладке, либо ссылки на старый дизайн
                window.open(link, target);
              } else {
                // _self для spa
                this.$router.push(link);
              }
            }
          });
      } else {
        // Во всех остальных поисках применяем фильтр по выбранному элементу
        this.searchFilter =
          this.searchModelName == SEARCH_MODE_PRODUCT
            ? autocompleteItem.label
            : autocompleteItem.value;
        this.applySearch();
      }
    },
    defineTarget,
  },
});
